import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-zoom-video',
    templateUrl: './zoom-video.component.html',
    styleUrls: ['./zoom-video.component.css']
})

export class ZoomVideoComponent implements OnInit {
    @Input() userName: string;
    @Input() token: string;
    @Input() idEpisode: number;
    @Input() idPatientAppointment: number;
    @Input() sessionName: string;
    @Output() leaveRoom = new EventEmitter<boolean>();
    
    isMuted: boolean = false;
    sessionContainer;
    wrapperContainer;
    controlsContainer;
    videoContainer;
    chatContainer;
    public leaveSession: Function;
    private uiToolkit;
    
    constructor(
        public zone: NgZone,
    ) {}
    
    ngOnInit(): void {
        this.leaveSession = this.sessionClosed.bind(this) 
    }
    async ngAfterContentInit(): Promise<void> {
        this.zone.runOutsideAngular(async () => {
          const toolkit = (await import('@zoom/videosdk-ui-toolkit')).default;
          this.uiToolkit = toolkit;
        });
    }
    startUIToolKit(){
        var config = {
            videoSDKJWT: this.token,
            sessionName: this.sessionName,
            userName: this.userName,
            sessionPasscode: this.idEpisode? this.idEpisode.toString() : this.idPatientAppointment.toString(),
            features: ['video', 'audio'],
            options: { init: {}, audio: {}, video: {}, share: {} },
            virtualBackground: {
                allowVirtualBackground: true,
                allowVirtualBackgroundUpload: true,
                virtualBackgrounds: [
                    'https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop'
                ]
            }
        }
        this.sessionContainer = document.getElementById('sessionContainer');
        // this.wrapperContainer = document.getElementById('wrapperContainer');
        // this.controlsContainer = document.getElementById('controlsContainer');
        // this.videoContainer = document.getElementById('videoContainer');
        // this.chatContainer = document.getElementById('chatContainer');

        // uitoolkit.showUitoolkitComponents(this.wrapperContainer, config)
        // uitoolkit.showControlsComponent(this.controlsContainer)
        // uitoolkit.showVideoComponent(this.videoContainer)
        // uitoolkit.showChatComponent(this.chatContainer)
        try{
            this.uiToolkit.joinSession(this.sessionContainer, config);
            this.uiToolkit.onSessionClosed(this.leaveSession)
            this.uiToolkit.onSessionJoined(this.sessionJoined)  
        }
        catch(error){
            console.log(error)
        }  
    }
    
    sessionJoined() {
        console.log('session joined')
    }
    
    sessionClosed(){
        console.log('session closed')
        this.leaveRoom.emit();
        // uitoolkit.hideControlsComponent(this.controlsContainer)
        // uitoolkit.hideVideoComponent(this.videoContainer)
        // uitoolkit.hideChatComponent(this.chatContainer)
        // uitoolkit.hideUitoolkitComponents(this.sessionContainer)
        this.uiToolkit.closeSession(this.sessionContainer);
    }

    onLeaveSession(){
        this.uiToolkit.offSessionClosed(this.leaveSession)
        this.uiToolkit.closeSession(this.sessionContainer)
        this.uiToolkit.leave
    }
}
