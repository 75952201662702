<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal">
            <h2>Horários - Aprazamento</h2>
         </div>
         <div class="body-modal">
            <app-loading-list *ngIf="firstLoading"></app-loading-list>
            <form class="form" [formGroup]="model" (ngSubmit)="submit()" *ngIf="!firstLoading">
                <div class="box">
                    <div class="row details-content">
                        <div class="col-4 details">
                            <p><b>Item:</b></p>
                            <ng-container *ngFor="let medicine of this.medicatedSolutionPrescriptionStruct.listMedicine">
                                <p>{{medicine.medicineName}}</p>
                            </ng-container>
                        </div>
                        <div class="col-4 details">
                            <p><b>Quantidade:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.quantity}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Unid.:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.usage}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Via:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.usage}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Frequência:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.frequencyName}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Diluição:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.dilutionName}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Apresentação:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.presentationName}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Período:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.treatmentPeriodName}}</p>
                        </div>
                        <div class="col-4 details">
                            <p><b>Observação da prescrição:</b></p>
                            <p>{{this.medicatedSolutionPrescriptionStruct.observation}}</p>
                        </div>
                    </div>
                </div>
                <div class="row box">
                    <div class="title-subheader">
                        <h3>Defina os horários</h3>
                    </div>
                    <div class="row scheduling-content">
                        <div class="col-12 col-md-6" formArrayName="listSchedulingDate"
                            *ngFor="let item of model.get('listSchedulingDate')['controls']; let i = index">
                            <div [formGroupName]="i">
                                <div class="row box">
                                    <div class="col-12 col-sm-6">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Data</mat-label>
                                            <input matInput inputmode="text" type="text" [mask]="masks.date" placeholder="dd/mm/aaaa"
                                                formControlName="date">
                                            <mat-error *ngIf="item.get('date').invalid">Data inválida</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Horário</mat-label>
                                            <input matInput type="text" formControlName="hour" [mask]="masks.timeUnit.mask" placeholder="00:00" 
                                                aria-label="Number">
                                            <mat-error *ngIf="item.get('hour').invalid">Horário inválido</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-array-buttons col-12 col-sm-4 col-lg-2" *ngIf="hasActionsButton">
                            <button class="side-button" color="primary" mat-mini-fab type="button" (click)="addNextInputDate()" [disabled]="buttonToSaveDisabled">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                            </button>
                            <button class="side-button" color="primary" mat-mini-fab type="button"
                                (click)="removeSchedulingDate(i)" *ngIf="model.get('listSchedulingDate')['controls'].length > 1" [disabled]="buttonToSaveDisabled">
                                <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                            </button>
                        </div>
                    </div>
                    <mat-error *ngIf="model.get('listSchedulingDate').errors?.datesNotInCrescentOrder">
                        As datas devem estar em ordem crescente.
                    </mat-error>

                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Observação sobre o planejamento</mat-label>
                            <textarea matInput type="text" formControlName="observation"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row box">
                    <div class="title-subheader">
                        <h3>Kits vinculados</h3>
                    </div>
                    <div class="card-list" *ngIf="this.medicatedSolutionPrescriptionStruct.listKit != null && this.medicatedSolutionPrescriptionStruct.listKit.length > 0">
                        <div class="row row-list">
                            <div class="col-12 col-sm-6" *ngFor="let item of this.medicatedSolutionPrescriptionStruct.listKit; let index = index;">
                                <div class="card-item">
                                    <div class="infos">
                                        <span class="primary">{{item.kit.kitName}}</span>
                                        <span class="secundary">{{item.kitType.kitTypeName}}</span>
                                    </div>
                                    <div class="actions">
                                        <a (click)="openKit(item)">
                                            <mat-icon aria-hidden="false" aria-label="Visualizar">
                                                visibility
                                            </mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-empty-list *ngIf="this.medicatedSolutionPrescriptionStruct.listKit == null 
                                           || this.medicatedSolutionPrescriptionStruct.listKit.length == 0"
                                    [message]="'Não há kits vinculados'">
                    </app-empty-list>
                </div>
                
                <div class="row box">
                    <div class="title-subheader">
                        <h3>Itens adicionais</h3>
                    </div>
                    <div *ngIf="model.get('listMaterials')['controls'].length == 0">
                        <button mat-flat-button type="button" class="add-item" color="primary"
                            (click)="addNextInputMaterial()" [disabled]="buttonToSaveDisabled">
                            <span *ngIf="isLoading == false">+ Adicionar item</span>
                            <mat-spinner [diameter]="30" class="mat-spinner-btn"
                                *ngIf="isLoading == true"></mat-spinner>
                        </button>
                    </div>
                    <div *ngIf="model.get('listMaterials')['controls'].length > 0" >
                        <div class="row" formArrayName="listMaterials"
                            *ngFor="let item of model.get('listMaterials')['controls']; let i = index">
                            <div class="col-12" [formGroupName]="i">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Selecione o material</mat-label>
                                            <mat-select formControlName="kitItem">
                                                <hr>
                                                <mat-label><b>&nbsp; &nbsp; Kits &#8628;</b></mat-label>
                                                <hr>
                                                <mat-option *ngFor="let kit of listKits" [value]="kit.idKit+'null'">
                                                    {{kit.codeWithName}}
                                                </mat-option>
                                                <hr>
                                                <mat-label><b>&nbsp; &nbsp; Itens &#8628;</b></mat-label>
                                                <hr>
                                                <mat-option *ngFor="let item of listItems" [value]="'null'+item.idItem">
                                                    {{item.itemName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-3">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Quantidade</mat-label>
                                            <input matInput type="number" formControlName="quantity" min="1"
                                                oninput="validity.valid||(value=null);" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6 col-sm-6 col-md-3">
                                        <button class="side-button " color="primary" mat-mini-fab type="button"
                                            (click)="addNextInputMaterial()" [disabled]="buttonToSaveDisabled">
                                            <mat-icon aria-hidden="false" aria-label="Menu apps">add</mat-icon>
                                        </button>
                                        <button class="side-button " color="primary" mat-mini-fab type="button"
                                            (click)="removeInputMaterial(i)" [disabled]="buttonToSaveDisabled">
                                            <mat-icon aria-hidden="false" aria-label="Menu apps">remove</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div mat-dialog-footer class="footer-modal">
    <div class="row">
        <div class="col-md-6">
            <button mat-flat-button type="button" color="primary" class=" btn-block" (click)="submit()" [disabled]="buttonToSaveDisabled">
                <span *ngIf="isLoading == false">Salvar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
        <div class="col-md-6">
            <button mat-flat-button type="button" color="accent" class=" btn-block" (click)="close()">
                <span *ngIf="isLoading == false">Cancelar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>