import { MaterialItemKitRequests } from "../../requests/pharmacy/RequestMaterial.request";
import { StatusSchedulingEnum } from "src/app/shared/enum/bed/status-scheduling.enum";
import { MedicalCareSadtStruct } from "./medical-care-sadt.struct";
import { SadtResultFileStruct } from "./sadt-result-file.struct";
import { ObservationScheduling } from "../../models/bed/observation-scheduling.model";

export class SadtGroupStruct {
    public medicalCareSadt: MedicalCareSadtStruct[] = [];
    public sadtResultFile: SadtResultFileStruct[] = [];
    public listMaterialItemKitRequest: MaterialItemKitRequests[] = [];

    public idMedicalCare: number;
    public idPlace: number;
    public idStatus: number;
    public idEpisode: number;
    public idUser: number;
    public idSadtGroup: number;
    public idTypeSadt: number;
    public idSadtSchedule: number;
    public idStatusScheduling: number;
    public idUserScheduling: number;

    public observation: string;
    public observationMedic: string;
    public schedulingObservation: string;
    public placeName: string;
    public statusName: string;
    public userName: string;
    public typeSadtName: string;
    public sadtResult: string;
    public sadtDescription: string;
    public sadtScheduleName: string;

    public datetimeStartAttendance: Date;
    public datetimeInclusion: Date;
    public datetimeCheckEstimative: Date;

    public isLoadingSadt: boolean;
    public fileLinkedLater: boolean;
    public isSaved: boolean;
    public isDisabled: boolean;

    public idSadtAttendance: number;
    public reasonSuspension: string;
    public datetimeCheckFulfilled: Date;

    // frontend only
    public shouldDisplayDatetimeCheck: boolean;

    public static canShouldDisplayDatetimeCheck(item: SadtGroupStruct, observationSchedulingConfig: ObservationScheduling): boolean {
        return observationSchedulingConfig?.useScheduling &&
            (item.idStatusScheduling === StatusSchedulingEnum.aprazado ||
            item.idStatusScheduling === StatusSchedulingEnum.concluido ||
            item.idStatusScheduling === StatusSchedulingEnum.Atrasado) &&
            !!item.datetimeCheckEstimative;
    }
}
