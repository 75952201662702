import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrescriptionSchedulingService } from 'src/app/shared/services/API/orchestrator-patient/prescription-scheduling.service';
import { ObservationScheduling } from 'src/app/shared/services/models/bed/observation-scheduling.model';
import { StatusScheduling } from 'src/app/shared/services/models/medical-record/status-scheduling.model';
import { MedicalPrescriptionStruct } from 'src/app/shared/services/structs/medical-record/medical-prescription.struct';
import { MedicalProcedureStruct } from 'src/app/shared/services/structs/medical-record/medical-procedure.struct';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { DateTimeStruct } from 'src/app/shared/services/structs/orchestrator-patient/date-time.struct';
import { MedicalRecordLookupService } from 'src/app/shared/services/API/medical-record/lookup.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ProcedureSchedulingModalComponent } from '../procedure-scheduling-modal/procedure-scheduling-modal.component';
import { SadtSchedulingModalComponent } from '../sadt-scheduling-modal/sadt-scheduling-modal.component';
import { StatusSchedulingEnum } from 'src/app/shared/enum/medical-record/status-scheduling.enum';
import { MedicatedSolutionStruct } from 'src/app/shared/services/structs/medical-record/medicated-solution.struct';
import { MedicatedSolutionSchedulingModalComponent } from '../medicated-solution-scheduling-modal/medicated-solution-scheduling-modal.component';
import { MedicalPrescriptionCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-prescription-check.request';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { GetListMedicineStruct } from 'src/app/shared/services/structs/pharmacy/get-list-medicine.struct';
import { MedicalProcedureCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-procedure-check.request';
import { MedicalPrescriptionModalComponent } from 'src/app/observation/pages/bed-management-details/presciption-list/medical-prescription-modal/medical-prescription-modal.component';
import { PrescriptionSchedulingModalComponent } from '../prescription-scheduling-modal/prescription-scheduling-modal.component';
import { MedicalProcedureModalComponent } from 'src/app/observation/pages/bed-management-details/procedure-list/medical-procedure-modal/medical-procedure-modal.component';
import { CheckRestrictedOverdueMedicationModalComponent } from '../../check-restricted-overdue-medication-modal/check-restricted-overdue-medication-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { UserRoleEnum } from 'src/app/shared/enum/user-role.enum';
import { MedicalPrescriptionCheckStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-prescription-check.struct';
import { VerifyPermissionService } from 'src/app/shared/services/API/user/verify-permission.service';
import { VerifyPermissionRequest } from 'src/app/shared/services/requests/user/verify-permission.request';
import { FrequencyEnum } from 'src/app/shared/enum/medical-record/frequency.enum';

@Component({
  selector: 'app-prescription-scheduling-table',
  templateUrl: './prescription-scheduling-table.component.html',
  styleUrls: ['./prescription-scheduling-table.component.css']
})

export class PrescriptionSchedulingTableComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private alertService: AlertService,
    public prescriptionSchedulingService: PrescriptionSchedulingService,
    public medicalRecordLookupService: MedicalRecordLookupService,
    public verifyPermissionService: VerifyPermissionService,
    private utilService: UtilService,
  ) { }

  @Input() listDateTime: DateTimeStruct[] = [];
  @Input() listMedicalPrescriptionStruct: MedicalPrescriptionStruct[] = [];
  @Input() listMedicatedSolutionPrescription: MedicatedSolutionStruct[] = [];
  @Input() listMedicalProcedureStruct: MedicalProcedureStruct[] = [];
  @Input() listSadtGroup: SadtGroupStruct[] = [];
  @Input() observationScheduling: ObservationScheduling;
  @Input() cycleTime: number;
  @Input() startDateCycle: Date;
  @Input() isChecking: boolean = false;
  //para checagem de medicamentos
  @Input() listCheckedMedication: MedicalPrescriptionCheckRequest[] = [];
  @Input() listCheckedProcedure: MedicalProcedureCheckRequest[];
  @Input() allergiesToSave: AllergyModel[] = [];
  @Input() listMedicine: GetListMedicineStruct;
  @Input() patientName: string;
  @Input() birthDate: Date;
  @Input() idEpisode: number;

  @Output() attPrescription = new EventEmitter<any>();
  @Output() checkPrescription = new EventEmitter<any>();
  @Output() checkProcedure = new EventEmitter<any>();
  @Output() procedureSigtapSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  public statusSchedulingEnum: typeof StatusSchedulingEnum = StatusSchedulingEnum;
  public frequencyEnum: typeof FrequencyEnum = FrequencyEnum;

  public showSubtitle: boolean = false;
  public isLoading: boolean = false;

  public listStatusScheduling: StatusScheduling[] = [];

  ngOnInit(): void {
    this.getStatusScheduling();
  }

  changeShowSubtitle() {
    this.showSubtitle = !this.showSubtitle;
  }

  openPrescriptionSchedulingModal(prescription: MedicalPrescriptionStruct, date: string, hour: number) {
    if (this.isChecking) {

      if ((!prescription.listMedicalPrescriptionCheck || prescription.listMedicalPrescriptionCheck.length == 0)
        && prescription.idFrequency != this.frequencyEnum.agora) {
        this.alertService.show('Alerta', "Não é possível realizar nenhuma interação, pois este item ainda não foi aprazado. Procure o ENFERMEIRO responsável.", AlertType.warning);
        return;
      }

      let [day, month, year] = date.split('/').map(Number);
      let dateStatus = new Date(year, (month - 1), day);
      let indexPrescriptionCheck = prescription.listMedicalPrescriptionCheck.findIndex(x => {
        let formatDate = new Date(x.datetimeCheckEstimative);
        return formatDate.getFullYear() == dateStatus.getFullYear()
          && formatDate.getMonth() == dateStatus.getMonth()
          && formatDate.getDate() == dateStatus.getDate()
          && formatDate.getHours() == hour
      });

      if (indexPrescriptionCheck == null || indexPrescriptionCheck == undefined) {
        this.alertService.show('Alerta', "Aprazamento não encontrado", AlertType.warning);
      }

      let medicalPrescriptionCheck = prescription.listMedicalPrescriptionCheck[indexPrescriptionCheck];
      if (prescription.listMedicalPrescriptionCheck.some(prescription => prescription.idStatusScheduling === StatusSchedulingEnum.Suspenso
        && prescription.idMedicalPrescriptionCheck !== medicalPrescriptionCheck.idMedicalPrescriptionCheck
        && prescription.datetimeCheckFulfilled == null)) {
        return this.alertService.show('Erro', "Existe uma checagem suspensa para esta prescrição. Salve a conduta para fazer uma nova checagem.", AlertType.error);
      }
      else if (prescription.listMedicalPrescriptionCheck.some(prescription => prescription.idStatusScheduling === StatusSchedulingEnum.Concluido
        && prescription.idMedicalPrescriptionCheck !== medicalPrescriptionCheck.idMedicalPrescriptionCheck
        && prescription.datetimeCheckFulfilled == null)) {
        return this.alertService.show('Erro', "Existe uma checagem concluida para esta prescrição. Salve a conduta para fazer uma nova checagem.", AlertType.error);
      }

      if ((medicalPrescriptionCheck && medicalPrescriptionCheck.idStatusScheduling === StatusSchedulingEnum.Aprazado) 
        && prescription.idFrequency != this.frequencyEnum.agora)
        return this.alertService.show('Erro', "Existe uma checagem anterior à ser feita.", AlertType.error);

      if ((medicalPrescriptionCheck && medicalPrescriptionCheck.idStatusScheduling === StatusSchedulingEnum.Atrasado )
        && prescription.idFrequency != this.frequencyEnum.agora) {
        let listRole = this.utilService.getRoles();
        let isMaster = this.utilService.getToken().isMaster;
        if (listRole.some(x => x.idRole !== UserRoleEnum.checar_atrasado_com_restrição) && !isMaster)
          return this.alertService.show('Erro!', "Usuário sem permissão para checar prescrição atrasada.", AlertType.error);
        else {
          const dialogRef = this.dialog.open(CheckRestrictedOverdueMedicationModalComponent, {
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm && result.justification && result.login && result.password) {
              let verifyPermissionRequest = new VerifyPermissionRequest();
              verifyPermissionRequest.login = result.login;
              verifyPermissionRequest.password = result.password;
              verifyPermissionRequest.permissionEnum = UserRoleEnum.liberador_checar_atrasado_com_restrição;

              this.verifyPermissionService.verifyPermission(verifyPermissionRequest).subscribe({
                next: (response) => {
                  if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    return;
                  }
                
                  if (response && response.havePermission) {
                    response.justification = result.justification;

                    this.openCheckPrescriptionModal(prescription, indexPrescriptionCheck, medicalPrescriptionCheck, response);
                  }
                  else
                    this.alertService.show('Erro', "O usuário não possui permissão para liberar a checagem com restrição.", AlertType.error);
                },
                error: (error) => {
                  console.log(error);
                  this.alertService.show('Erro inesperado', error, AlertType.error);
                }
              });
            }
          });
        }
      }
      else
        this.openCheckPrescriptionModal(prescription, indexPrescriptionCheck, medicalPrescriptionCheck);
    }
    else {
      const dialogRef = this.dialog.open(PrescriptionSchedulingModalComponent, {
        data: {
          observationScheduling: this.observationScheduling,
          cycleTime: this.cycleTime,
          startDateCycle: this.startDateCycle,
          prescriptionItem: prescription,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.prescription) {
          let index = this.listMedicalPrescriptionStruct.findIndex(x => x.idMedicalPrescription == result.prescription.idMedicalPrescription)
          if (index != null) {
            this.listMedicalPrescriptionStruct[index] = result.prescription;
            this.attPrescription.emit({ listMedicalPrescriptionStruct: this.listMedicalPrescriptionStruct });
          }
        }
      });
    }
  }

  openCheckPrescriptionModal(prescription: MedicalPrescriptionStruct, indexPrescriptionCheck: number, medicalPrescriptionCheck: MedicalPrescriptionCheckStruct, verifyPermissionResponse = null) {
    const dialogRef = this.dialog.open(MedicalPrescriptionModalComponent, {
      data: {
        prescription: prescription,
        patientName: this.patientName,
        birthDate: this.birthDate,
        actions: true,
        idEpisode: this.idEpisode,
        isMedicatedSolution: false,
        allergiesToSave: this.allergiesToSave,
        listMedicine: this.listMedicine,
        selectedMedicalPrescriptionCheck: medicalPrescriptionCheck,
        useScheduling: true,
        verifyPermissionResponse: verifyPermissionResponse
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.checkPrescription.emit(true);
        result.medicalPrescriptionCheckRequest.idMedicalPrescription = prescription.idMedicalPrescription;
        let indexCheck = this.listCheckedMedication.findIndex(l => l.idMedicalPrescription == result.medicalPrescriptionCheckRequest.idMedicalPrescription);
        if (indexCheck >= 0)
          this.listCheckedMedication[indexCheck] = result.medicalPrescriptionCheckRequest;
        else
          this.listCheckedMedication.push(result.medicalPrescriptionCheckRequest);

        let indexPrescription = this.listMedicalPrescriptionStruct.findIndex(x => x.idMedicalPrescription == prescription.idMedicalPrescription)
        if (indexPrescription != null) {
          medicalPrescriptionCheck.idStatusScheduling = result.medicalPrescriptionCheckRequest.idStatusScheduling;
          prescription.listMedicalPrescriptionCheck[indexPrescriptionCheck] = medicalPrescriptionCheck;
          this.listMedicalPrescriptionStruct[indexPrescription] = prescription;
        }
      }
    });
  }

  openMedicatedSolutionSchedulingModal(prescription: MedicatedSolutionStruct, date: string, hour: number) {
    if (this.isChecking) {

      if ((!prescription.listMedicalPrescriptionCheck || prescription.listMedicalPrescriptionCheck.length == 0)
        && prescription.idFrequency != this.frequencyEnum.agora) {
        this.alertService.show('Alerta', "Não é possível realizar nenhuma interação, pois este item ainda não foi aprazado. Procure o ENFERMEIRO responsável.", AlertType.warning);
        return;
      }

      let [day, month, year] = date.split('/').map(Number);
      let dateStatus = new Date(year, (month - 1), day);
      let indexPrescriptionCheck = prescription.listMedicalPrescriptionCheck.findIndex(x => {
        let formatDate = new Date(x.datetimeCheckEstimative);
        return formatDate.getFullYear() == dateStatus.getFullYear()
          && formatDate.getMonth() == dateStatus.getMonth()
          && formatDate.getDate() == dateStatus.getDate()
          && formatDate.getHours() == hour
      });

      if (indexPrescriptionCheck == null || indexPrescriptionCheck == undefined) {
        this.alertService.show('Alerta', "Aprazamento não encontrado", AlertType.warning);
      }

      let medicalPrescriptionCheck = prescription.listMedicalPrescriptionCheck[indexPrescriptionCheck];

      if (prescription.listMedicalPrescriptionCheck.some(prescription => prescription.idStatusScheduling === StatusSchedulingEnum.Suspenso
        && prescription.idMedicalPrescriptionCheck !== medicalPrescriptionCheck.idMedicalPrescriptionCheck
        && prescription.datetimeCheckFulfilled == null)) {
        return this.alertService.show('Erro', "Existe uma checagem suspensa para esta prescrição. Salve a conduta para fazer uma nova checagem.", AlertType.error);
      }
      else if (prescription.listMedicalPrescriptionCheck.some(prescription => prescription.idStatusScheduling === StatusSchedulingEnum.Concluido
        && prescription.idMedicalPrescriptionCheck !== medicalPrescriptionCheck.idMedicalPrescriptionCheck
        && prescription.datetimeCheckFulfilled == null)) {
        return this.alertService.show('Erro', "Existe uma checagem concluida para esta prescrição. Salve a conduta para fazer uma nova checagem.", AlertType.error);
      }

      if ((medicalPrescriptionCheck && medicalPrescriptionCheck.idStatusScheduling === StatusSchedulingEnum.Aprazado)
        && prescription.idFrequency != this.frequencyEnum.agora)
        return this.alertService.show('Erro', "Existe uma checagem anterior à ser feita.", AlertType.error);

      if ((medicalPrescriptionCheck && medicalPrescriptionCheck.idStatusScheduling === StatusSchedulingEnum.Atrasado)
        && prescription.idFrequency != this.frequencyEnum.agora) {
        let listRole = this.utilService.getRoles();
        let isMaster = this.utilService.getToken().isMaster;
        if (listRole.some(x => x.idRole !== UserRoleEnum.checar_atrasado_com_restrição) && !isMaster)
          return this.alertService.show('Erro!', "Usuário sem permissão para checar prescrição atrasada.", AlertType.error);
        else {
          const dialogRef = this.dialog.open(CheckRestrictedOverdueMedicationModalComponent, {
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm && result.justification && result.login && result.password) {
              let verifyPermissionRequest = new VerifyPermissionRequest();
              verifyPermissionRequest.login = result.login;
              verifyPermissionRequest.password = result.password;
              verifyPermissionRequest.permissionEnum = UserRoleEnum.liberador_checar_atrasado_com_restrição;

              this.verifyPermissionService.verifyPermission(verifyPermissionRequest).subscribe({
                next: (response) => {
                  if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    return;
                  }

                  if (response && response.havePermission) {
                    response.justification = result.justification;

                    this.openCheckPrescriptionSolutionModal(prescription, indexPrescriptionCheck, medicalPrescriptionCheck, response);
                  }
                  else
                    this.alertService.show('Erro', "O usuário não possui permissão para liberar a checagem com restrição.", AlertType.error);
                },
                error: (error) => {
                  console.log(error);
                  this.alertService.show('Erro inesperado', error, AlertType.error);
                }
              });
            }
          });
        }
      }
      else
        this.openCheckPrescriptionSolutionModal(prescription, indexPrescriptionCheck, medicalPrescriptionCheck);
    }
    else {
      const dialogRef = this.dialog.open(MedicatedSolutionSchedulingModalComponent, {
        data: {
          observationScheduling: this.observationScheduling,
          cycleTime: this.cycleTime,
          startDateCycle: this.startDateCycle,
          prescriptionItem: prescription,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.prescription) {
          let index = this.listMedicatedSolutionPrescription.findIndex(x => x.idMedicatedSolution == result.prescription.idMedicatedSolution)
          if (index != null) {
            this.listMedicatedSolutionPrescription[index] = result.prescription;
            this.attPrescription.emit({ listMedicatedSolutionPrescription: this.listMedicatedSolutionPrescription });
          }
        }
      });
    }
  }

  openCheckPrescriptionSolutionModal(prescription, indexPrescriptionCheck, medicalPrescriptionCheck, verifyPermissionResponse = null) {
    const dialogRef = this.dialog.open(MedicalPrescriptionModalComponent, {
      data: {
        prescription: prescription,
        patientName: this.patientName,
        birthDate: this.birthDate,
        actions: true,
        idEpisode: this.idEpisode,
        isMedicatedSolution: true,
        allergiesToSave: this.allergiesToSave,
        listMedicine: this.listMedicine,
        selectedMedicalPrescriptionCheck: medicalPrescriptionCheck,
        useScheduling: true,
        verifyPermissionResponse: verifyPermissionResponse
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.checkPrescription.emit(true);
        prescription.listMedicine.forEach(item => {

          let medicalPrescriptionCheck: MedicalPrescriptionCheckRequest = { ...result.medicalPrescriptionCheckRequest };
          medicalPrescriptionCheck.idMedicalPrescription = item.idMedicalPrescription;
          medicalPrescriptionCheck.idMedicalPrescriptionCheck = item.idMedicalPrescriptionCheck ? item.idMedicalPrescriptionCheck : null;

          let index = this.listCheckedMedication.findIndex(l => l.idMedicalPrescription == medicalPrescriptionCheck.idMedicalPrescription);
          if (index >= 0)
            this.listCheckedMedication[index] = medicalPrescriptionCheck;
          else
            this.listCheckedMedication.push(medicalPrescriptionCheck);
        });

        let indexPrescription = this.listMedicatedSolutionPrescription.findIndex(x => x.idMedicatedSolution == prescription.idMedicatedSolution)
        if (indexPrescription != null) {
          medicalPrescriptionCheck.idStatusScheduling = result.medicalPrescriptionCheckRequest.idStatusScheduling;
          prescription.listMedicalPrescriptionCheck[indexPrescriptionCheck] = medicalPrescriptionCheck;
          this.listMedicatedSolutionPrescription[indexPrescription] = prescription;
        }
      }
    });
  }

  openProcedureSchedulingModal(prescription: MedicalProcedureStruct, date: string, hour: number) {
    if (this.isChecking) {

      if (!prescription.listMedicalProcedureCheck || prescription.listMedicalProcedureCheck.length == 0) {
        this.alertService.show('Alerta', "Não é possível realizar nenhuma interação, pois este item ainda não foi aprazado. Procure o ENFERMEIRO responsável.", AlertType.warning);
        return;
      }

      let [day, month, year] = date.split('/').map(Number);
      let dateStatus = new Date(year, (month - 1), day);
      let indexProcedureCheck = prescription.listMedicalProcedureCheck.findIndex(x => {
        let formatDate = new Date(x.datetimeCheckEstimative);
        return formatDate.getFullYear() == dateStatus.getFullYear()
          && formatDate.getMonth() == dateStatus.getMonth()
          && formatDate.getDate() == dateStatus.getDate()
          && formatDate.getHours() == hour
      });

      if (indexProcedureCheck == null || indexProcedureCheck == undefined) {
        this.alertService.show('Alerta', "Aprazamento não encontrado", AlertType.warning);
      }

      let medicalProcedureCheck = prescription.listMedicalProcedureCheck[indexProcedureCheck];

      if (prescription.listMedicalProcedureCheck.some(prescription => prescription.idStatusScheduling === StatusSchedulingEnum.Suspenso
        && prescription.idMedicalProcedureCheck !== medicalProcedureCheck.idMedicalProcedureCheck
        && prescription.datetimeCheckFulfilled == null)) {
        return this.alertService.show('Erro', "Existe uma checagem suspensa para esta prescrição. Salve a conduta para fazer uma nova checagem.", AlertType.error);
      }
      else if (prescription.listMedicalProcedureCheck.some(prescription => prescription.idStatusScheduling === StatusSchedulingEnum.Concluido
        && prescription.idMedicalProcedureCheck !== medicalProcedureCheck.idMedicalProcedureCheck
        && prescription.datetimeCheckFulfilled == null)) {
        return this.alertService.show('Erro', "Existe uma checagem concluida para esta prescrição. Salve a conduta para fazer uma nova checagem.", AlertType.error);
      }

      if (medicalProcedureCheck.idStatusScheduling === StatusSchedulingEnum.Aprazado)
        return this.alertService.show('Erro', "Existe uma checagem anterior à ser feita.", AlertType.error);

      if (medicalProcedureCheck.idStatusScheduling === StatusSchedulingEnum.Atrasado) {
        let listRole = this.utilService.getRoles();
        let isMaster = this.utilService.getToken().isMaster;
        if (listRole.some(x => x.idRole !== UserRoleEnum.checar_atrasado_com_restrição) && !isMaster)
          return this.alertService.show('Erro!', "Usuário sem permissão para checar prescrição atrasada.", AlertType.error);
        else {
          const dialogRef = this.dialog.open(CheckRestrictedOverdueMedicationModalComponent, {
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result && result.confirm && result.justification && result.login && result.password) {
              let verifyPermissionRequest = new VerifyPermissionRequest();
              verifyPermissionRequest.login = result.login;
              verifyPermissionRequest.password = result.password;
              verifyPermissionRequest.permissionEnum = UserRoleEnum.liberador_checar_atrasado_com_restrição;

              this.verifyPermissionService.verifyPermission(verifyPermissionRequest).subscribe({
                next: (response) => {
                  if (response.isError) {
                    this.alertService.show('Erro', response.errorDescription, AlertType.error);
                    return;
                  }

                  if (response && response.havePermission) {
                    response.justification = result.justification;

                    this.openCheckProcedureModal(prescription, indexProcedureCheck, medicalProcedureCheck, response);
                  }
                  else
                    this.alertService.show('Erro', "O usuário não possui permissão para liberar a checagem com restrição.", AlertType.error);
                },
                error: (error) => {
                  console.log(error);
                  this.alertService.show('Erro inesperado', error, AlertType.error);
                }
              });
            }
          });
        }
      }
      else
        this.openCheckProcedureModal(prescription, indexProcedureCheck, medicalProcedureCheck);
    }
    else {
      const dialogRef = this.dialog.open(ProcedureSchedulingModalComponent, {
        data: {
          observationScheduling: this.observationScheduling,
          cycleTime: this.cycleTime,
          startDateCycle: this.startDateCycle,
          prescriptionItem: prescription,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.prescription) {
          let index = this.listMedicalProcedureStruct.findIndex(x => x.idMedicalProcedure == result.prescription.idMedicalProcedure)
          if (index != null) {
            this.listMedicalProcedureStruct[index] = result.prescription;
            this.attPrescription.emit({ listMedicalProcedureStruct: this.listMedicalProcedureStruct });
          }
        }
      });
    }
  }

  openCheckProcedureModal(prescription, indexProcedureCheck, medicalProcedureCheck, verifyPermissionResponse = null) {
    const dialogRef = this.dialog.open(MedicalProcedureModalComponent, {
      data: {
        procedure: prescription,
        patientName: this.patientName,
        birthDate: this.birthDate,
        idMedicalProcedure: prescription.idMedicalProcedure,
        actions: true,
        idEpisode: this.idEpisode,
        selectedMedicalProcedureCheck: medicalProcedureCheck,
        useScheduling: true,
        verifyPermissionResponse: verifyPermissionResponse
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.checkProcedure.emit(true);
        let index = this.listCheckedProcedure.findIndex(l => l.idMedicalProcedure == result.medicalProcedureCheckRequest.idMedicalProcedure);
        if (index >= 0)
          this.listCheckedProcedure[index] = result.medicalProcedureCheckRequest
        else
          this.listCheckedProcedure.push(result.medicalProcedureCheckRequest);
        if (result.idProcedureSigtap)
          this.procedureSigtapSelected.emit(result.idProcedureSigtap);


        let indexProcedure = this.listMedicalProcedureStruct.findIndex(x => x.idMedicalProcedure == prescription.idMedicalProcedure)
        if (indexProcedure != null) {
          medicalProcedureCheck.idStatusScheduling = result.medicalProcedureCheckRequest.idStatusScheduling;
          prescription.listMedicalProcedureCheck[indexProcedureCheck] = medicalProcedureCheck;
          this.listMedicalProcedureStruct[indexProcedure] = prescription;
        }
      }
    });
  }

  openSadtSchedulingModal(prescription: SadtGroupStruct) {
    const dialogRef = this.dialog.open(SadtSchedulingModalComponent, {
      data: {
        observationScheduling: this.observationScheduling,
        cycleTime: this.cycleTime,
        startDateCycle: this.startDateCycle,
        prescriptionItem: prescription,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.prescription) {
        let index = this.listSadtGroup.findIndex(x => x.idSadtGroup == result.prescription.idSadtGroup)
        if (index != null) {
          this.listSadtGroup[index] = result.prescription;
          this.attPrescription.emit({ listSadtGroup: this.listSadtGroup });
        }
      }
    });
  }

  getStatusScheduling() {
    this.medicalRecordLookupService.getStatusScheduling().subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        if (response.listStatusScheduling && response.listStatusScheduling.length > 0) {
          this.listStatusScheduling = response.listStatusScheduling;
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.alertService.show('Erro inesperado', error, AlertType.error);
      }
    });
  }

}