export class MedicalPrescriptionCheckRequest {

    public idMedicalPrescription: number;
    public idMedicalPrescriptionCheck: number;
    public idStatusScheduling: number;
    public idUserAuthorization: number;
    public idUserRequest: number;
    
    public checkName: boolean;
    public checkMedication: boolean;
    public checkAdministration: boolean;
    public hasPatientDenied: boolean;
    public hasComplaints: boolean;

    public complaints: string;
    public administrationLocal: string;
    public reasonSuspension: string;
    public justification: string;
}