import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-room-occupied-remove-modal',
  templateUrl: './room-occupied-remove-modal.component.html',
  styleUrls: ['./room-occupied-remove-modal.component.css']
})
export class RoomOccupiedRemoveModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<RoomOccupiedRemoveModalComponent>) { }

  public title: string;
  public informationString: string = `Você será redirecionado para a seleção de sala.`;

  ngOnInit(): void {
    if(this.data.title)
      this.title = this.data.title;
    else
      this.title = `<b>${this.data.userName}</b> agora está utilizando esta sala!`;
    if(this.data.information)
      this.informationString = this.data.information;
  }

  close() {
    this.matDialogRef.close();
  }

  continue() {
    this.matDialogRef.close();
  }

  clickCancel() {
    this.matDialogRef.close();
  }
}

