import { MedicalProcedureCheckModel } from "../../models/medical-record/medical-procedure-check.model";
import { MaterialItemKitRequests } from "../../requests/pharmacy/RequestMaterial.request";

export class MedicalProcedureCheckStruct extends MedicalProcedureCheckModel
{
    public userName: string;
    public professionName: string;

    public medicalProcedureStatus: number;
    public idUserScheduling: number;

    public datetimeCheckEstimative: Date;
    public datetimeCheckFulfilled: Date;
    
    public listRequestMaterialsItemKit: MaterialItemKitRequests[] = [];
}