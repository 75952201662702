<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div *ngIf="this.patient">
            <div class="section-container">
                <div class="title-header">
                    <h1>Dados Pessoais</h1>
                </div>
                <div class="row section-personal-data">
                    <div *ngIf="patient.socialName">
                        <span><b>Nome Social: </b></span>
                        <span>{{patient.socialName}}</span>
                    </div>
                    <div *ngIf="patient.patientName">
                        <span><b>Nome: </b></span>
                        <span>{{patient.patientName}}</span>
                    </div>
                    <div *ngIf="patient.birthDate">
                        <span><b>Data de Nascimento: </b></span>
                        <span>{{patient.birthDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div *ngIf="patient.birthDate">
                        <span><b>Idade: </b></span>
                        <span>{{patient.patientAge}}</span>
                    </div>
                    <!-- Antigo Gênero -->
                    <div *ngIf="patient.genderName">
                        <span><b>Sexo: </b></span>
                        <span>{{patient.genderName}}</span>
                    </div>
                    <div *ngIf="patient.genderIdentityName">
                        <span><b>Gênero: </b></span>
                        <span>{{patient.genderIdentityName}}</span>
                    </div>
                    <div *ngIf="patient.cpf">
                        <span><b>CPF: </b></span>
                        <span>{{patient.cpf}}</span>
                    </div>
                    <div *ngIf="patient.cns">
                        <span><b>CNS: </b></span>
                        <span>{{patient.cns}}</span>
                    </div>
                    <div *ngIf="patient.phone1">
                        <span><b>Telefone 1: </b></span>
                        <span>{{patient.phone1}}</span>
                    </div>
                    <div *ngIf="patient.phone2">
                        <span><b>Telefone 2: </b></span>
                        <span>{{patient.phone2}}</span>
                    </div>
                    <div *ngIf="patient.raceName">
                        <span><b>Raça: </b></span>
                        <span>{{patient.raceName}}</span>
                    </div>
                    <div *ngIf="patient.motherName">
                        <span><b>Nome da Mãe: </b></span>
                        <span>{{patient.motherName}}</span>
                    </div>
                    <div *ngIf="patient.fatherName">
                        <span><b>Nome do Pai: </b></span>
                        <span>{{patient.fatherName}}</span>
                    </div>
                    <div *ngIf="patient.profession">
                        <span><b>Profissão: </b></span>
                        <span>{{patient.profession}}</span>
                    </div>
                    <div *ngIf="patient.educationName">
                        <span><b>Escolaridade: </b></span>
                        <span>{{patient.educationName}}</span>
                    </div>
                    <div *ngIf="patient.rg">
                        <span><b>RG: </b></span>
                        <span>{{patient.rg}}</span>
                    </div>
                    <div *ngIf="patient.expeditionIssuer">
                        <span><b>Órgão Expeditor: </b></span>
                        <span>{{patient.expeditionIssuer}}</span>
                    </div>
                    <div *ngIf="patient.expeditionDate">
                        <span><b>Data de Expedição: </b></span>
                        <span>{{patient.expeditionDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                    <div *ngIf="patient.birthCountry">
                        <span><b>Nacionalidade: </b></span>
                        <span>{{patient.birthCountry}}</span>
                    </div>
                    <div *ngIf="patient.birthState">
                        <span><b>Estado de Origem: </b></span>
                        <span>{{patient.birthState}}</span>
                    </div>
                    <div *ngIf="patient.birthCity">
                        <span><b>Cidade de Origem: </b></span>
                        <span>{{patient.birthCity}}</span>
                    </div>
                </div>
            </div>
            <div class="section-container">
                <div class="title-header">
                    <h1>Endereço</h1>
                </div>
                <div class="row section-address">
                    <div *ngIf="patient.zipCode">
                        <span><b>CEP: </b></span>
                        <span>{{patient.zipCode}}</span>
                    </div>
                    <div *ngIf="patient.street">
                        <span><b>Logradouro: </b></span>
                        <span>{{patient.street}}</span>
                    </div>
                    <div *ngIf="patient.houseNumber">
                        <span><b>Número: </b></span>
                        <span>{{patient.houseNumber}}</span>
                    </div>
                    <div *ngIf="patient.apartmentNumber">
                        <span><b>Complemento: </b></span>
                        <span>{{patient.apartmentNumber}}</span>
                    </div>
                    <div *ngIf="patient.neighborhood">
                        <span><b>Bairro: </b></span>
                        <span>{{patient.neighborhood}}</span>
                    </div>
                    <div *ngIf="patient.city">
                        <span><b>Cidade: </b></span>
                        <span>{{patient.city}}</span>
                    </div>
                    <div *ngIf="patient.state">
                        <span><b>Estado: </b></span>
                        <span>{{patient.state}}</span>
                    </div>
                    <div *ngIf="patient.country">
                        <span><b>País: </b></span>
                        <span>{{patient.country}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-footer class="footer-modal col-12">
    <div class="col-12 col-md-4">
        <button mat-flat-button color="accent" class="btn-block" (click)="clickClosed()" type="button">
            <span>Fechar</span>
        </button>
    </div>
    <div class="col-12 col-md-4">
        <button mat-flat-button color="primary" class="btn-block" (click)="clickEdit()" type="button">
            <span>Editar dados</span>
        </button>
    </div>
</div>