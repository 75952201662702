import { ValidatorFn, ValidationErrors, FormArray } from "@angular/forms";

export const dateHourInCrescentOrderValidator: ValidatorFn = (formArray: FormArray): ValidationErrors | null => {
  let invalid = false;
  
  if (formArray.length <= 0) {
    return;
  }

  const firstDateValue = formArray.at(0).value.date.toString();
  let firstHourValue = formArray.at(0).value.hour.toString();
  const day = parseInt(firstDateValue.substring(0, 2), 10);
  const month = parseInt(firstDateValue.substring(2, 4), 10) - 1; // Mês é base zero
  const year = parseInt(firstDateValue.substring(4), 10);
  const hour = parseInt(firstHourValue.substring(0, 2), 10);
  const minute = parseInt(firstHourValue.substring(2, 4), 10);
  const currentDateTime = new Date(year, month, day, hour, minute, 0);

  // Data e hora atuais
  const now = new Date();

  // Verifica se o primeiro item é menor que a data e hora atual
  if (currentDateTime < now) {
    invalid = true;
    return { dateLessThanCurrentDate: true };
  }

  for (let i = 0; i < formArray.length - 1; i++) {
    let currentDateValue = formArray.at(i).value.date.toString();
    let currentHourValue = formArray.at(i).value.hour.toString();
    const currentDay = parseInt(currentDateValue.substring(0, 2), 10);
    const currentMonth = parseInt(currentDateValue.substring(2, 4), 10) - 1; // Mês é base zero
    const currentYear = parseInt(currentDateValue.substring(4), 10);
    const currentHour = parseInt(currentHourValue.substring(0, 2), 10);
    const currentMinute = parseInt(currentHourValue.substring(2, 4), 10);
    const currentDate = new Date(currentYear, currentMonth, currentDay, currentHour, currentMinute, 0);

    let nextDateValue = formArray.at(i + 1).value.date.toString();
    let nextHourValue = formArray.at(i + 1).value.hour.toString();
    const nextDay = parseInt(nextDateValue.substring(0, 2), 10);
    const nextMonth = parseInt(nextDateValue.substring(2, 4), 10) - 1; // Mês é base zero
    const nextYear = parseInt(nextDateValue.substring(4), 10);
    const nextHour = parseInt(nextHourValue.substring(0, 2), 10);
    const nextMinute = parseInt(nextHourValue.substring(2, 4), 10);
    const nextDate = new Date(nextYear, nextMonth, nextDay, nextHour, nextMinute, 0);
    
    if (isNaN(currentDate.getTime()) || isNaN(nextDate.getTime())) {
      continue;
    }

    if (currentDate >= nextDate) {
      invalid = true;
      break;
    }
  }

  return invalid ? { datesNotInCrescentOrder: true } : null;
};