export class MedicalProcedureCheckRequest {
    public idMedicalProcedure: number;
    public idMedicalProcedureCheck: number;
    public idStatusScheduling: number;
    public idUserCheckRestrictedAuthorization: number;
    public idUserCheckRestrictedRequest: number;
    public idUserAuthorization: number;
    public idUserRequest: number;
    public checkName: boolean;
    public checkProcedure: boolean;

    public reasonSuspension: string;
    public justification: string;
}