import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientBarCodeModalComponent } from 'src/app/shared/components/patient-bar-code-modal/patient-bar-code-modal.component';
import { AdministrationTypeEnum } from 'src/app/shared/enum/prescription.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { UserGetNameService } from 'src/app/shared/services/API/user/user-get-name.service';
import { MedicalPrescriptionCheckRequest } from 'src/app/shared/services/requests/medical-record/medical-prescription-check.request';
import { MedicalPrescriptionCheckStruct } from 'src/app/shared/services/structs/orchestrator-patient/medical-prescription-check.struct';
import { ListUserStruct } from 'src/app/shared/services/structs/user/list-user.struct';
import { ObservationMedicineBarCodeModalComponent } from '../../observation-medicine-bar-code-modal/observation-medicine-bar-code-modal.component';
import { PrescricptionCheckHistoryModalComponent } from './prescricption-check-history-modal/prescricption-check-history-modal.component';
import { PrescriptionStatusEnum } from 'src/app/shared/enum/prescription-status.enum';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { AllergyModel } from 'src/app/shared/services/models/medical-record/allergy.model';
import { KitWithItemsStruct } from 'src/app/shared/services/structs/pharmacy/kit-with-items.struct';
import { KitItemsModalComponent } from 'src/app/shared/components/kit-items-modal/kit-items-modal.component';
import { SuspendPrescriptionScheduleModalComponent } from 'src/app/shared/components/suspend-prescription-schedule-modal/suspend-prescription-schedule-modal.component';
import { StatusSchedulingEnum } from 'src/app/shared/enum/medical-record/status-scheduling.enum';
import { VerifyPermissionResponse } from 'src/app/shared/services/responses/user/verify-permission.response';

@Component({
  selector: 'app-medical-prescription-modal',
  templateUrl: './medical-prescription-modal.component.html',
  styleUrls: ['./medical-prescription-modal.component.css']
})
export class MedicalPrescriptionModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<MedicalPrescriptionModalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public userGetNameService: UserGetNameService,
  ) { }

  public model: FormGroup;

  public isLoading: boolean;
  public showLocal: boolean;
  public showComplaints: boolean;
  public checkedMedicine: boolean;
  public checkedPatient: boolean;
  public isReadonly: boolean;
  public isMedicatedSolution: boolean = false;
  public useScheduling: boolean = false;

  public nonDispensedStatus = PrescriptionStatusEnum.NaoDispensado;
  public releasedStatus = PrescriptionStatusEnum.Liberado;
  public openedStatus = PrescriptionStatusEnum.EmAberto;
  public statusSchedulingEnum: typeof StatusSchedulingEnum = StatusSchedulingEnum;
  allergiesToSave: AllergyModel[] = this.data.allergiesToSave ? this.data.allergiesToSave : [];
  public user: ListUserStruct;
  public thisMedicalPrescriptionCheck: MedicalPrescriptionCheckStruct;
  public verifyPermissionResponse: VerifyPermissionResponse = this.data.verifyPermissionResponse;

  public checkDatetimeFulfilled: Date;

  public observationSelect: string;
  public checkProfessionName: string;
  public checkUserName: string;
  public reasonSuspension: string;

  public prescription: any;

  public idStatusScheduling: number;

  ngOnInit(): void {
    if (this.data != null && this.data.isMedicatedSolution != null)
      this.isMedicatedSolution = this.data.isMedicatedSolution;

    this.isLoading = false;
    this.showLocal = false;
    this.checkedMedicine = false;
    this.checkedPatient = false;
    this.showComplaints = false;
    this.isReadonly = false;
    this.prescription = this.data.prescription;

    if (this.data.useScheduling != null)
      this.useScheduling = this.data.useScheduling;

    if (this.data.selectedMedicalPrescriptionCheck)
      this.thisMedicalPrescriptionCheck = this.data.selectedMedicalPrescriptionCheck
    else
      this.thisMedicalPrescriptionCheck = this.prescription.thisMedicalPrescriptionCheck;

    this.model = this.formBuilder.group({
      checkName: ['', [Validators.required]],
      checkMedication: ['', [Validators.required]],
      checkAdministration: ['', [Validators.required]],
      administrationLocal: [''],
      hasPatientDenied: [''],
      hasComplaints: [''],
      complaints: [''],
    });
    if (this.thisMedicalPrescriptionCheck && this.thisMedicalPrescriptionCheck != null)
      this.populatePrescriptionCheck();
    else if (this.prescription.idAdministrationType != AdministrationTypeEnum.naoDefinida)
      this.model.get('administrationLocal').setValue(this.prescription.administrationTypeName)
  }

  submit() {
    if (!this.isFormValid() || this.isLoading) {
      this.alertService.show('Erro', 'É necessário verificar (marcar) todos os itens para realizar a liberação', AlertType.error);
      return;
    }

    const medicalPrescriptionCheckRequest = this.mapToRequest();

    this.processCheck(medicalPrescriptionCheckRequest);
  }

  private isFormValid(): boolean {
    return this.model.valid && this.model.get('checkName').value && this.model.get('checkMedication').value && this.model.get('checkAdministration').value;
  }

  private hasAllergyInMedicineList(): boolean {
    return this.prescription?.listMedicine?.some(medicine => medicine.patientHasAllergy) ?? false;
  }

  private getAllergyMedicines(): string[] {
    return this.prescription.listMedicine
      ?.filter(medicine => medicine.patientHasAllergy)
      .map(medicine => medicine.description) ?? [];
  }

  private createAllergyDescription(allergyMedicines: string[]): string {
    if (allergyMedicines.length > 0) {
      return `O paciente é alérgico a um dos princípios ativos deste medicamento: ${allergyMedicines}. \nDeseja prosseguir com a checagem?`;
    }
    else if (allergyMedicines.length > 1) {
      return `O paciente é alérgico a um ou mais princípios ativos destes medicamentos: ${allergyMedicines.join(', ')}. \nDeseja prosseguir com a checagem?`;
    }
    return 'O paciente é alérgico a um dos princípios ativos deste medicamento. \nDeseja prosseguir com a checagem?';
  }

  private showAllergyWarning(description: string) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      data: {
        hasImage: true,
        isTwoButtonsModal: true,
        title: 'Atenção!',
        description: description
      }
    });
    return dialogRef.afterClosed();
  }

  private processCheck(medicalPrescriptionCheckRequest: MedicalPrescriptionCheckRequest) {
    this.isLoading = true;
    this.updateOrCreateCheck();
    this.matDialogRef.close({ medicalPrescriptionCheckRequest });
  }

  getPrescriptionCheckData() {
    this.checkDatetimeFulfilled = this.thisMedicalPrescriptionCheck.datetimeCheckFulfilled;
    this.checkProfessionName = this.thisMedicalPrescriptionCheck.professionName;
    this.checkUserName = this.thisMedicalPrescriptionCheck.userName;

    if (this.prescription?.patientHasAllergy || this.hasAllergyInMedicineList()) {
      const allergyMedicines = this.getAllergyMedicines();
      const description = this.createAllergyDescription(allergyMedicines);
      this.showAllergyWarning(description).subscribe(result => {
        if (!result?.confirm)
          this.close();
      });
    }
    else if (this.allergiesToSave && this.allergiesToSave.length > 0) {
      let allergyDetected: boolean = false;

      allergyDetected = this.data.listMedicine.some((medicine) =>
        medicine.listAllergy?.some((allergyId) =>
          this.allergiesToSave.some((savedAllergy) => savedAllergy.idAllergy === allergyId)
        )
      );
      if (allergyDetected) {
        const description = this.createAllergyDescription([]);

        this.showAllergyWarning(description).subscribe(result => {
          if (!result?.confirm)
            this.close();
        });
      }
    }
  }

  mapToRequest(): MedicalPrescriptionCheckRequest {
    let medicalPrescriptionCheckRequest: MedicalPrescriptionCheckRequest = new MedicalPrescriptionCheckRequest();

    medicalPrescriptionCheckRequest.checkAdministration = this.model.get('checkAdministration').value ? this.model.get('checkAdministration').value : false;
    medicalPrescriptionCheckRequest.administrationLocal = this.model.get('administrationLocal').value ? this.model.get('administrationLocal').value : null;
    medicalPrescriptionCheckRequest.checkMedication = this.model.get('checkMedication').value ? this.model.get('checkMedication').value : false;
    medicalPrescriptionCheckRequest.checkName = this.model.get('checkName').value ? this.model.get('checkName').value : false;
    medicalPrescriptionCheckRequest.complaints = this.model.get('complaints').value ? this.model.get('complaints').value : null;
    medicalPrescriptionCheckRequest.hasComplaints = this.model.get('hasComplaints').value ? this.model.get('hasComplaints').value : false;
    medicalPrescriptionCheckRequest.hasPatientDenied = this.model.get('hasPatientDenied').value ? this.model.get('hasPatientDenied').value : false;
    medicalPrescriptionCheckRequest.idMedicalPrescriptionCheck = this.thisMedicalPrescriptionCheck?.idMedicalPrescriptionCheck;
    medicalPrescriptionCheckRequest.reasonSuspension = this.reasonSuspension;
    medicalPrescriptionCheckRequest.idStatusScheduling = this.idStatusScheduling ? this.idStatusScheduling : this.statusSchedulingEnum.Concluido;

    if (this.verifyPermissionResponse) {
      medicalPrescriptionCheckRequest.idUserAuthorization = this.verifyPermissionResponse.idUser;
      medicalPrescriptionCheckRequest.idUserRequest = this.verifyPermissionResponse.idUserRequest;
      medicalPrescriptionCheckRequest.justification = this.verifyPermissionResponse.justification;
    }

    return medicalPrescriptionCheckRequest;
  }

  updateOrCreateCheck() {
    if (!this.thisMedicalPrescriptionCheck) {
      this.prescription.thisMedicalPrescriptionCheck = new MedicalPrescriptionCheckStruct();
      this.thisMedicalPrescriptionCheck = this.prescription.thisMedicalPrescriptionCheck;
    }

    this.thisMedicalPrescriptionCheck.checkAdministration = this.model.get('checkAdministration').value ? this.model.get('checkAdministration').value : false;
    this.thisMedicalPrescriptionCheck.administrationLocal = this.model.get('administrationLocal').value ? this.model.get('administrationLocal').value : null;
    this.thisMedicalPrescriptionCheck.checkMedication = this.model.get('checkMedication').value ? this.model.get('checkMedication').value : false;
    this.thisMedicalPrescriptionCheck.checkName = this.model.get('checkName').value ? this.model.get('checkName').value : false;
    this.thisMedicalPrescriptionCheck.complaints = this.model.get('complaints').value ? this.model.get('complaints').value : null;
    this.thisMedicalPrescriptionCheck.hasComplaints = this.model.get('hasComplaints').value ? this.model.get('hasComplaints').value : false;
    this.thisMedicalPrescriptionCheck.hasPatientDenied = this.model.get('hasPatientDenied').value ? this.model.get('hasPatientDenied').value : false;

    this.prescription.tempCheck = true;

    this.isLoading = false;
  }

  checkAdministration(event) {
    this.showLocal = event.checked;
  }

  selectionChange() {
    if (this.observationSelect == "2") {
      this.showComplaints = true;
      this.model.get('hasComplaints').setValue(true);
      this.model.get('hasPatientDenied').setValue(false);

      this.model.get('checkName').setValidators([Validators.required]);
      this.model.get('checkName').updateValueAndValidity();
      this.model.get('checkMedication').setValidators([Validators.required]);
      this.model.get('checkMedication').updateValueAndValidity();
      this.model.get('checkAdministration').setValidators([Validators.required]);
      this.model.get('checkAdministration').updateValueAndValidity();
      this.model.get('complaints').setValidators([Validators.required]);
      this.model.get('complaints').updateValueAndValidity();
    }
    else if (this.observationSelect == "1") {
      this.showComplaints = false;
      this.model.get('hasComplaints').setValue(false);
      this.model.get('hasPatientDenied').setValue(true);

      this.model.get('checkName').setValidators(null);
      this.model.get('checkName').updateValueAndValidity();
      this.model.get('checkMedication').setValidators(null);
      this.model.get('checkMedication').updateValueAndValidity();
      this.model.get('checkAdministration').setValidators(null);
      this.model.get('checkAdministration').updateValueAndValidity();
      this.model.get('complaints').setValidators(null);
      this.model.get('complaints').updateValueAndValidity();
    }
    else {
      this.showComplaints = false;
      this.model.get('hasComplaints').setValue(false);
      this.model.get('hasPatientDenied').setValue(false);

      this.model.get('checkName').setValidators([Validators.required]);
      this.model.get('checkName').updateValueAndValidity();
      this.model.get('checkMedication').setValidators([Validators.required]);
      this.model.get('checkMedication').updateValueAndValidity();
      this.model.get('checkAdministration').setValidators([Validators.required]);
      this.model.get('checkAdministration').updateValueAndValidity();
      this.model.get('complaints').setValidators(null);
      this.model.get('complaints').updateValueAndValidity();
    }
  }

  populatePrescriptionCheck() {
    this.model.get('hasComplaints').setValue(this.thisMedicalPrescriptionCheck.hasComplaints);
    this.model.get('complaints').setValue(this.thisMedicalPrescriptionCheck.complaints)
    this.model.get('hasPatientDenied').setValue(this.thisMedicalPrescriptionCheck.hasPatientDenied);
    this.model.get('checkName').setValue(this.thisMedicalPrescriptionCheck.checkName);
    this.model.get('checkMedication').setValue(this.thisMedicalPrescriptionCheck.checkMedication);
    this.model.get('checkAdministration').setValue(this.thisMedicalPrescriptionCheck.checkAdministration);
    this.model.get('administrationLocal').setValue(this.thisMedicalPrescriptionCheck.administrationLocal)

    this.showLocal = this.thisMedicalPrescriptionCheck.checkAdministration;
    this.showComplaints = this.thisMedicalPrescriptionCheck.hasComplaints;
    if (this.thisMedicalPrescriptionCheck.hasComplaints == true)
      this.observationSelect = "2";
    else if (this.thisMedicalPrescriptionCheck.hasPatientDenied == true)
      this.observationSelect = "1";
    else
      this.observationSelect = "";

    this.isReadonly = (this.thisMedicalPrescriptionCheck.datetimeCheckFulfilled != null || this.prescription.tempCheck);
    if (this.isReadonly) {
      this.model.get('hasComplaints').disable();
      this.model.get('complaints').disable();
      this.model.get('hasPatientDenied').disable();
      this.model.get('checkName').disable();
      this.model.get('checkMedication').disable();
      this.model.get('checkAdministration').disable();
      this.model.get('administrationLocal').disable();
    }
    this.getPrescriptionCheckData();
  }
  close() {
    this.matDialogRef.close();
  }

  openMedicineBarCodeModal(idMedicine: number) {
    const dialogRef = this.dialog.open(ObservationMedicineBarCodeModalComponent, {
      data: {
        idMedicine: idMedicine,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.checkMedicine) {
        this.model.get('checkMedication').setValue(true);
      }
    });
  }
  openPatientBarCodeModal() {
    const dialogRef = this.dialog.open(PatientBarCodeModalComponent, {
      data: {
        idEpisode: this.data.idEpisode,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.checkPatient) {
        this.model.get('checkName').setValue(true);
      }
    });
  }

  statusIdToText(status: number): string {
    if (status == 1)
      return "Medicação Próxima";
    else if (status == 2)
      return "Medicação Adiantada";
    else if (status == 3)
      return "Medicação Atrasada";
  }

  openCheckHistoryModal() {
    const dialogRef = this.dialog.open(PrescricptionCheckHistoryModalComponent, {
      data: {
        listMedicalPrescriptionCheck: this.prescription.listMedicalPrescriptionCheck,
      },
    });
  }

  openKit(kit: KitWithItemsStruct) {
    const dialogRef = this.dialog.open(KitItemsModalComponent, {
      width: "600px",
      data: {
        kit: kit
      },
    });
  }

  suspendPrescriptionModal() {
    const dialogRef = this.dialog.open(SuspendPrescriptionScheduleModalComponent, {
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm && result.reasonSuspension) {
        this.reasonSuspension = result.reasonSuspension;
        this.idStatusScheduling = StatusSchedulingEnum.Suspenso;

        const medicalPrescriptionCheckRequest = this.mapToRequest();

        this.processCheck(medicalPrescriptionCheckRequest);
      }
    });
  }
}