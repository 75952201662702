<div class="row attendance-status">
    <button *ngIf="!isFlowTherapeuticPlanning" [disabled]="isLoading" mat-flat-button (click)="selectOption('naoAtendidos')" [color]="naoAtendidosColor"
        class="btn-block col-12 col-md-4">
        <span>Não atendidos</span>
    </button>
    <button *ngIf="!isFlowManagement && !isFlowTherapeuticPlanning" [disabled]="isLoading" mat-flat-button (click)="selectOption('atendidos')" [color]="atendidosColor"
        class="btn-block col-12 col-md-4">
        <span>Atendidos</span>
    </button>
    <button *ngIf="!isFlowManagement && !isFlowTherapeuticPlanning" [disabled]="isLoading" mat-flat-button (click)="selectOption('evadidos')" [color]="evadidosColor"
        class="btn-block col-12 col-md-4">
        <span>Evadidos</span>
    </button>
    <button *ngIf="!isFlowTherapeuticPlanning" matBadge="{{returnCount}}" matBadgePosition="after" matBadgeColor="warn" [disabled]="isLoading"
        mat-flat-button (click)="selectOption('retornados')" [color]="retornadosColor"
        class="btn-block col-12 col-md-4">
        <span>Retornos</span>
    </button>
    <button *ngIf="!isFlowManagement && hasScheduleFilter && !isFlowTherapeuticPlanning" matBadge="{{scheduleCount}}" matBadgePosition="after" matBadgeColor="warn" [disabled]="isLoading"
        mat-flat-button (click)="selectOption('agendados')" [color]="agendadosColor" class="btn-block col-12 col-md-4">
        <span>Agendados</span>
    </button>
    <button *ngIf="isFlowManagement" [disabled]="isLoading" mat-flat-button (click)="selectOptionKPI('beyondGoal')" [color]="beyondGoalColor"
        class="btn-block col-12 col-md-4">
        <span>Fora da meta</span>
    </button>
    <button *ngIf="isFlowManagement" [disabled]="isLoading" mat-flat-button (click)="selectOptionKPI('alert')" [color]="alertColor"
        class="btn-block col-12 col-md-4">
        <span>Alerta</span>
    </button>
    <button *ngIf="isFlowManagement" [disabled]="isLoading" mat-flat-button (click)="selectOptionKPI('ok')" [color]="okColor"
        class="btn-block col-12 col-md-4">
        <span>Dentro da meta</span>
    </button>
    <button *ngIf="isFlowTherapeuticPlanning" [disabled]="isLoading" mat-flat-button (click)="selectOptionTherapeuticPlanning('notScheduling')" [color]="notSchedulingColor"
        class="btn-block col-12 col-md-4">
        <span>Itens a aprazar</span>
    </button>
    <button *ngIf="isFlowTherapeuticPlanning" [disabled]="isLoading" mat-flat-button (click)="selectOptionTherapeuticPlanning('scheduling')" [color]="schedulingColor"
        class="btn-block col-12 col-md-4">
        <span>Itens aprazados</span>
    </button>
</div>