import { Injectable } from "@angular/core";
import { DiscriminatorTypeEnum } from "../shared/enum/discriminator-type.enum";
import { PainEnum } from "../shared/enum/pain.enum";
import { PriorityColorModel } from "../shared/services/models/risk-classification/priority-color.model";
import { BillingDataRequest } from "../shared/services/requests/billing/billing-data-request";
import { PatientRequest } from "../shared/services/requests/medical-record/patient.request";
import { DeviceTypeSectorResponse } from "../shared/services/responses/risk-classification/device-type-sector.response";
import { FlowStruct } from "../shared/services/structs/flow/flow.struct";
import { AllergyStruct } from "../shared/services/structs/medical-record/allergy.struct";
import { CounselingStruct } from "../shared/services/structs/risk-classification/counseling.struct";
import { DiscriminatorStruct } from "../shared/services/structs/risk-classification/discriminator.struct";
import { SelfTriageStruct } from "../shared/services/structs/self-triage/self-triage.struct";
import { PatientStruct } from "../shared/services/structs/self-triage/patient.struct";
import { QuestionAnswerStruct } from "../shared/services/structs/self-triage/question-answer.struct";
import { PatientFlowchartStruct } from "../shared/services/structs/self-triage/patient-flowchart.struct";
import { PatientDeviceStruct } from "../shared/services/structs/sync-measurer/patient-device.struct";
import { SelfTriageResponse } from "../shared/services/responses/self-triage/self-triage.response";
import { RoomMeasurerStruct } from "../shared/services/structs/risk-classification/room-measurer.struct";
import { NumericLiteral } from "typescript";
import { NextStepsAfterTriageProtocol } from "../shared/services/models/care-line-priority/next-steps-after-triage-protocol.model";
import { HealthGuidelineCategorization } from "../shared/services/models/risk-classification/health-guideline-categorization.model";
import { HealthGuideline } from "../shared/services/models/risk-classification/health-guideline.model";
import { DiscriminadorHeartRateSleepy, DiscriminadorHeartRegular, DiscriminadorSaturationAtmosphericAir } from "../shared/enum/risk-classification/discriminators.enum";


@Injectable({
    providedIn: 'root'
})
export class UtilsClassificationService {
    constructor() { }
    updateClassificationData(classificationData: ClassificationData) {
        sessionStorage.setItem("classificationData", JSON.stringify(classificationData));
    }

    removeClassificationData() {
        sessionStorage.removeItem("classificationData");
    }

    getClassificationData() {
        let classificationData: ClassificationData = JSON.parse(sessionStorage.getItem("classificationData"))
        return classificationData ? classificationData : new ClassificationData();
    }

    updateDevicetypeBySector(deviceTypeSector: DeviceTypeSectorResponse) {
        sessionStorage.setItem("deviceTypeService", JSON.stringify(deviceTypeSector));
    }

    removeDevicetypeBySector() {
        sessionStorage.removeItem("deviceTypeService");
    }


    getDevicetypeBySector(): DeviceTypeSectorResponse {
        let deviceTypeSectorResponse: DeviceTypeSectorResponse = JSON.parse(sessionStorage.getItem("deviceTypeService"))
        return deviceTypeSectorResponse;
    }

    updateDisableShowReport(disableShowReport: boolean) {
        sessionStorage.setItem("disableShowReport", JSON.stringify(disableShowReport));
    }

    removeDisableShowReport() {
        sessionStorage.removeItem("disableShowReport");
    }


    getDisableShowReport(): boolean {
        let disableShowReport: boolean = JSON.parse(sessionStorage.getItem("disableShowReport"))
        return disableShowReport;
    }

    getIdService():number{
        let idService: number = JSON.parse(sessionStorage.getItem('idService'))
        return idService;
    }

    updateIdService(idService: number) {
        sessionStorage.setItem("idService", JSON.stringify(idService));
    }

    removeIdService() {
        sessionStorage.removeItem("idService");
    }

    updateFlowControl(flowControl: number) {
        sessionStorage.setItem("flowControl", JSON.stringify(flowControl));
    }

    getFlowControl() {
        let flowControl: number = JSON.parse(sessionStorage.getItem("flowControl"));
        return flowControl ? flowControl : 1;
    }

    removeFlowControl() {
        sessionStorage.removeItem("flowControl");
    }

    updateHideTemperatureChildSepsis(hideTemperature: boolean) {
        sessionStorage.setItem("hideTemperature", JSON.stringify(hideTemperature));
    }
    getHideTemperatureChildSepsis(): boolean {
        let hideTemperature: boolean = JSON.parse(sessionStorage.getItem("hideTemperature"))
        return hideTemperature;
    }
    removeHideTemperatureChildSepsis() {
        sessionStorage.removeItem("hideTemperature");
    }

    verifySelectDiscriminator(discriminator: any, value: string, description: string, state: string): boolean {
        switch (discriminator.idDiscriminatorType) {
            case DiscriminatorTypeEnum.Temperatura:
            case DiscriminatorTypeEnum.Glicemia:
            case DiscriminatorTypeEnum.Numerico:
                return this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, parseFloat(value));
            case DiscriminatorTypeEnum.FrequenciaRespiratoria:
                if (discriminator.minValueAllowed != null && discriminator.maxValueAllowed != null) {
                    return !this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, parseFloat(value));
                }
                return this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, parseFloat(value));
            case DiscriminatorTypeEnum.GlicemiaCapilar:
                return this.verifySelectCappilaryGlucose(discriminator, parseFloat(value), description);
            case DiscriminatorTypeEnum.Saturacao:
                return this.verifySelectSaturation(discriminator, parseFloat(value), description);
            case DiscriminatorTypeEnum.FrequenciaCardiaca:
                return this.verifySelectHeartRate(discriminator, parseFloat(value), state, false);
            case DiscriminatorTypeEnum.PressaoArterial:
                let value1 = value ? parseFloat(value.split("/")[0]) : null;
                let value2 = value ? parseFloat(value.split("/")[1]) : null;
                return this.verifySelectPressure(discriminator, value1, value2);
            case DiscriminatorTypeEnum.Dor:
            case DiscriminatorTypeEnum.DorCoceira:
                return this.verifySelectPain(discriminator, parseFloat(value), parseFloat(description));
            default:
                return false;
        }
    }
    verifySelectSaturation(discriminator: any, value: number, description: string): boolean {
        let result = false;
        if (description == DiscriminadorSaturationAtmosphericAir.atmosphericAir.toString()) {
            result = this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, value);
        } else {
            result = this.verifySelectGeneric(discriminator.minValue2Allowed, discriminator.maxValue2Allowed, value);
        }
        return result;
    }
    verifySelectHeartRate(discriminator: any, value: number, description: string, validade: boolean): boolean {
        if (!validade) {
            return false;
        }
        let result = false;
        if (description != DiscriminadorHeartRateSleepy.sleepy.toString()) {
            result = !this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, value);
            if (discriminator.minValueAllowed == null || discriminator.maxValueAllowed == null) {
                return !result;
            }
        } else {
            result = !this.verifySelectGeneric(discriminator.minValue2Allowed, discriminator.maxValue2Allowed, value);
        }
        return result;
    }
    verifySelectPressure(discriminator: any, value: number, value2: number): boolean {
        let result1 = this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, value);
        let result2 = this.verifySelectGeneric(discriminator.minValue2Allowed, discriminator.maxValue2Allowed, value2);
        if (discriminator.minValue2Allowed == null && discriminator.maxValue2Allowed == null) {
            return result1;
        }
        return result1 || result2;
    }
    verifySelectCappilaryGlucose(discriminator: any, value: number, description: string) {
        let select = this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, value);
        if (select) {
            return description == "sim";
        }
        return select;
    }
    verifySelectPain(discriminator: any, value: number, description: number): boolean {
        let select = this.verifySelectGeneric(discriminator.minValueAllowed, discriminator.maxValueAllowed, value);
        if (discriminator.maxValue2Allowed && discriminator.minValue2Allowed) {
            if (description == PainEnum.level1 || description == PainEnum.level2 || description == PainEnum.level3 || description == PainEnum.level4) {
                select = true;
            } else {
                select = false;
            }
        }
        return select;
    }
    verifySelectGeneric(minValueAllowed: number, maxValueAllowed: number, value: number): boolean {
        if ((minValueAllowed == 0 || minValueAllowed) && (maxValueAllowed || maxValueAllowed == 0)) {
            return (value >= minValueAllowed && value <= maxValueAllowed);
        } else if ((minValueAllowed || minValueAllowed == 0) && !maxValueAllowed) {
            return value < minValueAllowed;
        } else if (!minValueAllowed && (maxValueAllowed || maxValueAllowed == 0)) {
            return value > maxValueAllowed;
        }
        return false;
    }

    createEpisodeData(episodeData: EpisodeData) {
        sessionStorage.setItem("episodeData", JSON.stringify(episodeData));
    }

    getEpisodeData() {
        let episodeData: EpisodeData = JSON.parse(sessionStorage.getItem("episodeData"))
        return episodeData;
    }

    createHealthGuidelineTriageData(healthGuidelineTriageData: HealthGuidelineTriageData) {
        sessionStorage.setItem("healthGuidelineTriageData", JSON.stringify(healthGuidelineTriageData));
    }

    gethealtHGuidelineTriageData() {
        let healthGuidelineTriageData: HealthGuidelineTriageData = JSON.parse(sessionStorage.getItem("healthGuidelineTriageData"))
        return healthGuidelineTriageData;
    }

    removeHealtHGuidelineTriageData() {
        sessionStorage.removeItem("healthGuidelineTriageData");
    }
}

export class Reclassification {
    public idGravity: number;
    public idFlowchart: number;
    public flowchart: string;
    public score: boolean;
}

export class ClassificationData {
    public idLastFlowchart: number;
    public lastFlowchart: string;
    public indexLastValueSet: number;
    public isPatientWhite: boolean;
    public newPatient: boolean;
    public conclusionNotes: string;
    public priority: PriorityColorModel;
    public datetimeStartTriage: Date;
    public discriminator: DiscriminatorStruct;
    public idPatient: number;
    public idEpisode: number;
    public idProtocol: number;
    public careLineProtocolName: string;
    public listNextStepsAfterTriageProtocolDescription: string[] = [];
    public protocolName: string;
    public idSector: number;
    public idRoom: number;
    public birthDate: Date;
    public ageDay: number;
    public ageMonth: number;
    public ageYears: number;
    public idGender: number;
    public idGenderIdentity: number;
    public idFlowchart: number;
    public isPunctuationSystem: boolean;
    public flowchartName: string;
    public complain: string;
    public idArrivalReason: number;
    public arrivalReason: string;
    public idSpecialNecessity: number;
    public specialNecessity: string;
    public specialNecessityName: string;
    public observation: string;
    public outOfOrder: boolean;
    public discriminatorValues: any[];
    public discriminatorAllValues: any[];
    public isAutomatic: boolean;
    public forward: any;
    public forwardObservation: string;
    public radioFallRisk: boolean;
    public weight: number;
    public height: any;
    public idSuspicion: number;
    public suspicion: string;
    public isSuspicionEvaluated: boolean;
    public suspicionEvaluationMotive: string;
    public datetimeSuspicionEvaluation: Date;
    public datetimeRegisterProtocolCareLine: Date;
    public idUserSuspicionEvaluation: number;
    public listIdComorbodity: number[];
    public comorbidities: string;
    public hasComorbidityOutros: boolean;
    public listAllergy: AllergyStruct[];
    public diseaseHistory: string;
    public arrivalReasonDesc: string;
    public device: PatientDeviceStruct;
    public glasgowMotor: number;
    public glasgowVerbal: number;
    public glasgowEye: number;
    public idDeviceType: number;
    public reclassification: Reclassification;
    public isReclassification: boolean;
    public uriThermometer: string;
    public uriOximeter: string;
    public uriPressure: string;
    public uriGlucometer: string;
    public macAddressThermometer: string;
    public macAddressOximeter: string;
    public macAddressPressure: string;
    public macAddressGlucometer: string;
    public deviceThermometer: RoomMeasurerStruct;
    public deviceOximeter: RoomMeasurerStruct;
    public devicePressure: RoomMeasurerStruct;
    public deviceGlucometer: RoomMeasurerStruct;
    public billingData: BillingDataRequest;
    public discriminatorsPersist: any[][];
    public isTelephoneClassification: boolean;
    public patientData: PatientRequest;
    public hasFlowchartIA: boolean;
    public iaResult: string;
    public listCounseling: CounselingStruct[];
    public AllListCounseling: CounselingStruct[];
    public flowSequence: FlowStruct[];
    public painColorCode: string;
    public metadata: string;
    public telemedicineCheck: boolean;
    public temperatureComplementaryData: number;
    public heartRateComplementaryData: number;
    public bloodPressureDiastoleComplementaryData: number;
    public bloodPressureSystoleComplementaryData: number;
    public respiratoryFrequencyComplementaryData: number;
    public glucoseComplementaryData: number;
    public saturationComplementaryData: number;
    public perfusaoCapilar: boolean = null;
    public selfTriage: SelfTriageResponse;
    public perfusaoCapilarValue: number = null;
    public idFlowchartType: number;
    public frequencyPulseState: string;
    public frequencyPulseType: string;
    public idCareLinePriority: number;
    public idCareLinePriorityStatus: number;
    public idOrigin: number;
    public idArrivalType: number;
    public isUnidentifiedPatient: boolean;
    public idGrievanceNotification: number;
    public listIdPriority: number[];
    public priorityAutistic: boolean;
    public openProtocolTriage: boolean;
    public discriminatorSelectionMotive: string;
    public recurringReturn: boolean;
    public recurringReturnConfiguredTime: string;
    public lastCanBeDenied: boolean;
    public saveGuideGrievanceNotification: boolean;
}

export class EpisodeData {
    public idQueue: number;
    public idEpisode: number;
    public idPatient: number;
    public actualTicket: string;
    public lastTicket: string;
    public waitingTime: string;
}

export class HealthGuidelineTriageData{
    public healthGuideline: HealthGuideline;
    public healthGuidelineCategorization: HealthGuidelineCategorization;
    public datetimeStartSymptom: Date
}