<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="row">
                <div class="col-md-6">
                    <div class="head-profile-modal-list">
                        <h2>Procedimento: </h2>
                    </div>
                    <div class="body-confirm-modal-list">
                        <div class="row">
                            <div class="col-md-12">
                                <label>Nome procedimento: </label>
                                <p>{{procedure.procedureName}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Observação: </label>
                                <p>{{procedure.observation}}</p>
                            </div>
                        </div>
                        <div *ngIf="this.useScheduling" class="check-estimative-details">
                            <div class="col-12">
                                <span class="name-detail">Data e hora da checagem: </span>
                                <span>Data: {{this.medicalProcedureCheck.datetimeCheckEstimative | date:'dd/MM/yyyy'}}</span>
                                <span>Hora: {{this.medicalProcedureCheck.datetimeCheckEstimative | date:'HH:mm'}}</span>
                            </div>
                            <div *ngIf="this.medicalProcedureCheck.schedulingObservation">
                                <span class="name-detail">Observação do aprazamento: </span>
                                <span>{{this.medicalProcedureCheck.schedulingObservation}}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="data.actions && procedure.idProcedureStatus != closedStatus">
                            <div class="row">
                                <div class="col-12">
                                    <button mat-flat-button type="button" color="primary" class="btn-block"
                                        (click)="openPatientBarCodeModal()">Ler paciente</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-1">
                                    <mat-checkbox matInput formControlName="checkName"></mat-checkbox>
                                </div>
                                <div class="col-md-11">
                                    <label>Nome paciente e Data de nascimento: </label>
                                    <p>{{this.data.patientName}}, {{this.data.birthDate | date:'dd/MM/yyyy'}}</p>
                                </div>
                                <div class="col-md-1">
                                    <mat-checkbox matInput formControlName="checkProcedure"></mat-checkbox>
                                </div>
                                <div class="col-11">
                                    <label>Nome do procedimento: </label>
                                    <p>{{procedure.procedureName}}</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="procedure.listMedicalProcedureCheck">
                    <div class="head-profile-modal-list">
                        <h2>Histórico do Procedimento: </h2>
                    </div>
                    <div class="row" *ngFor="let item of procedure.listMedicalProcedureCheck">
                        <div class="col-md-12">
                            <label *ngIf="item.datetimeCheckFulfilled">
                                Checado
                            </label>
                            <p>{{item.datetimeCheckFulfilled | date:'dd/MM/yyyy HH:mm'}}</p>
                            <p>{{item.professionName}} - {{item.userName}}</p>
                        </div>
                    </div>
                </div>
                <div class="footer-modal-list">
                    <button *ngIf="!isReadonly && data.actions" mat-flat-button color="primary" class="btn-block" type="submit">
                        <span *ngIf="isLoading == false">Concluir</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button *ngIf="!isReadonly && data.actions" mat-flat-button color="accent" class="btn-block" type="button" (click)="suspendPrescriptionModal()">
                        <span *ngIf="isLoading == false">Suspender horário</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                    <button mat-flat-button type="button" color="primary" class="btn-block"
                        (click)="close()"><span>Fechar</span></button>
                </div>
            </div>
        </form>
    </div>
</div>