<div mat-dialog-content>
    <div class="mat-dialog-header">
        <div class="alert-img">
            <img src="assets/images/alert.png" aria-hidden="false" aria-label="alert" />
        </div>
        <h1><strong>Atenção!</strong></h1>
        <p>{{this.description}}</p>
    </div>
    <div class="mat-dialog-content">
        <div class="sub-section" *ngIf="listInvalidMedicine != null && listInvalidMedicine.length > 0">
            <h3>Medicamentos</h3>
        </div>
        <div class="card-list">
            <div class="card-container">
                <ng-container *ngIf="listInvalidMedicine != null && listInvalidMedicine.length > 0">
                    <div *ngFor="let item of listInvalidMedicine">
                        <div class="card-prescription">
                            <div class="infos">
                                <span class="primary invalid-medicin" matTooltip="{{item.medicineName}}">{{item.medicineName}}</span>
                                <span class="secundary">Uso: {{item.usage}}</span>
                                <span class="secundary">Via: {{item.administrationTypeName}}</span>
                                <span class="secundary">Apresentação: {{item.presentationName}}</span>
                                <span class="secundary">Frequência: {{item.frequencyName}}</span>
                                <span class="secundary">Periodo trat.:
                                    {{item.treatmentPeriodName}}</span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="listInvalidWithExam != null && listInvalidWithExam.length > 0">
                    <div *ngFor="let item of listInvalidWithExam">
                        <div class="card-prescription">
                            <div class="infos">
                                <span class="primary" matTooltip="{{item.examName}}">{{item.examName}}</span>
                                <span class="secundary">Status: {{ !item.isActive ? 'Inativo' : 'Deletado' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="listInvalidWithExamType != null && listInvalidWithExamType.length > 0">
                    <div *ngFor="let item of listInvalidWithExamType">
                        <div class="card-prescription">
                            <div class="infos">
                                <span class="primary" matTooltip="{{item.examTypeName}}">{{item.examTypeName}}</span>
                                <span class="secundary">Status: {{ !item.isActive ? 'Inativo' : 'Deletado' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-section" *ngIf="listInvalidMedicatedSolution != null && listInvalidMedicatedSolution.length > 0">
            <h3>Solução medicamentosa</h3>
        </div>
        <div class="card-list" *ngIf="listInvalidMedicatedSolution && listInvalidMedicatedSolution.length > 0">
            <div class="card-container">
                <div *ngFor="let item of listInvalidMedicatedSolution; let index = index;">
                    <div class="card-prescription">
                        <div class="infos">
                            <div *ngFor="let medicine of item.listMedicine; let indexMedicine = index;">
                                <span class="primary" [ngClass]="{'invalid-medicine': !medicine.isValidToMedic}">{{medicine.medicineName}}</span>
                            </div>
                            <span class="secundary">Uso: {{item.usage}}</span>
                            <span class="secundary">Via: {{item.administrationTypeName}}</span>
                            <span class="secundary">Apresentação: {{item.presentationName}}</span>
                            <span class="secundary">Frequência: {{item.frequencyName}}</span>
                            <span class="secundary">Periodo trat.: {{item.treatmentPeriodName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer-panel-modal-list">
    <div class="row">
        <div class="col-6">
            <a mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">Cancelar</a>
        </div>
        <div class="col-6">
            <button mat-flat-button type="submit" color="primary" (click)="clickOk()" class=" btn-block">
                <span>Ok</span>
            </button>
        </div>
    </div>
</div>