import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { CommonService } from 'src/app/shared/services/common-service';
import { environment } from 'src/environments/environment';
import { VerifyPermissionRequest } from '../../requests/user/verify-permission.request';
import { VerifyPermissionResponse } from '../../responses/user/verify-permission.response';

@Injectable({
  providedIn: 'root'
})
export class VerifyPermissionService extends CommonService {

  constructor(private httpClient: HttpClient) { super(); }

  public verifyPermission(request: VerifyPermissionRequest): Observable<VerifyPermissionResponse> {
    let uri = `VerifyPermission`;

    return this.httpClient.post<VerifyPermissionResponse>(environment.urlApiUser + uri, request, this.addHeaderToken())
      .pipe(catchError(this.handleError));
  }
}
