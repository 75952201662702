export enum FrequencyEnum {
    x1Horas = 1,
    x2Horas = 2,
    x3Horas = 3,
    x4Horas = 4,
    x6Horas = 5,
    x8Horas = 6,
    x12Horas = 7,
    x1Dia = 8,
    x1Noite = 9,
    outros = 10,
    criterioMedico = 11,
    seNecessario = 12,
    agora = 13,
    continuo = 14,
}