import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-kit-items-modal',
  templateUrl: './kit-items-modal.component.html',
  styleUrls: ['./kit-items-modal.component.css']
})
export class KitItemsModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<KitItemsModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.matDialogRef.close();
  }
}
