import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common-service';
import { GetPrescriptionSchedulingRequest } from '../../requests/medical-record/get-prescription-scheduling.request';
import { ListPrescriptionSchedulingResponse } from '../../responses/medical-record/list-prescription-scheduling.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { SchedulingRequest } from '../../requests/orchestrator-patient/scheduling.request';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionSchedulingService extends CommonService {

  constructor(private router: Router, private httpClient: HttpClient) {
    super();
  }

  public getListPrescription(request: GetPrescriptionSchedulingRequest): Observable<ListPrescriptionSchedulingResponse> {
    let uri = `PrescriptionScheduling/GetListPrescription`
    return this.httpClient.post<ListPrescriptionSchedulingResponse>(environment.urlApiOrchestratorPatient + uri, request, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
  }

  public postPrescriptionScheduling(body: SchedulingRequest): Observable<ReturnStruct>{
    let uri = `PrescriptionScheduling`

    return this.httpClient.post<ReturnStruct>(environment.urlApiOrchestratorPatient + uri, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    );
  }
}
