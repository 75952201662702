import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Masks, MaskService } from 'src/app/shared/services/mask.service';
import { SadtGroupStruct } from 'src/app/shared/services/structs/medical-record/sadt-group.struct';
import { dateHourInCrescentOrderValidator } from 'src/app/shared/custom-validators/date-hour-form-array-order.validator';
import { ObservationScheduling } from 'src/app/shared/services/models/bed/observation-scheduling.model';
import { FrequencyEnum } from 'src/app/shared/enum/medical-record/frequency.enum';
import { KitMultiselectStruct } from 'src/app/shared/services/structs/pharmacy/kit-multiselect.struct';
import { ItemService } from 'src/app/shared/services/API/pharmacy/Item.service';
import { KitService } from 'src/app/shared/services/API/pharmacy/kit.service';
import { ItemModel } from 'src/app/shared/services/models/pharmacy/item.model';
import { StatusSchedulingEnum } from 'src/app/shared/enum/medical-record/status-scheduling.enum';

@Component({
  selector: 'app-sadt-scheduling-modal',
  templateUrl: './sadt-scheduling-modal.component.html',
  styleUrls: ['./sadt-scheduling-modal.component.css']
})
export class SadtSchedulingModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<SadtSchedulingModalComponent>,
    private formBuilder: FormBuilder,
    private maskService: MaskService,
    private alertService: AlertService,
    public itemService: ItemService,
    public kitService: KitService,
  ) { }

  public frequencyEnum: typeof FrequencyEnum = FrequencyEnum;
  public statusSchedulingEnum: typeof StatusSchedulingEnum = StatusSchedulingEnum;
  
  public model: FormGroup;
  public masks: Masks;
  
  public listKits: KitMultiselectStruct[] = [];
  public listItems: ItemModel[] = [];

  public sadtGroupStruct: SadtGroupStruct = null;
  public observationScheduling: ObservationScheduling;

  public isLoading: boolean = false;
  public firstLoading: boolean = true;
  public hasActionsButton: boolean = false;
  public buttonToSaveDisabled: boolean = false;

  public prescriptionType: number;
  public cycleTime: number;

  public startDateCycle: Date;

  ngOnInit(): void {
    this.firstLoading = false;
    this.model = this.formBuilder.group({
      listSchedulingDate: this.formBuilder.array([], { validators: dateHourInCrescentOrderValidator }),
      observation: [""],
      listMaterials: this.formBuilder.array([]),
    });

    this.observationScheduling = this.data.observationScheduling;
    this.cycleTime = this.data.cycleTime;
    this.startDateCycle = this.data.startDateCycle;
    this.prescriptionType = this.data.prescriptionType;

    if(this.data && this.data.prescriptionItem) {
      this.sadtGroupStruct = this.data.prescriptionItem;
    }
    
    this.masks = this.maskService.getMasks();
    this.populate();
  }
  
  submit(){
    //colocar lógica de reaprazamento depois
    const listSchedulingDateForm = this.model.get('listSchedulingDate') as FormArray;

    if(listSchedulingDateForm.errors?.dateLessThanCurrentDate){
      this.alertService.show('Erro', "As datas não podem ser menores que a data de hoje!", AlertType.error);
      return;
    }

    if(this.model.invalid){
      return;
    }

    if (listSchedulingDateForm) {
      let inputDate = this.model.get('listSchedulingDate')['controls'][0].get('date').value;
      let inputHour = this.model.get('listSchedulingDate')['controls'][0].get('hour').value;
      let day = parseInt(inputDate.substring(0, 2), 10);
      let month = parseInt(inputDate.substring(2, 4), 10) - 1; // Mês é base zero
      let year = parseInt(inputDate.substring(4), 10);
      let hour = parseInt(inputHour.substring(0, 2), 10);
      let minute = parseInt(inputHour.substring(2, 4), 10);

      this.sadtGroupStruct.datetimeCheckEstimative =  new Date(year, month, day, hour, minute, 0);
      this.sadtGroupStruct.idStatusScheduling = this.statusSchedulingEnum.Aprazado;
      this.sadtGroupStruct.schedulingObservation = this.model.get('observation').value;
    }
    this.matDialogRef.close({prescription: this.sadtGroupStruct}); 
    
    this.matDialogRef.close();
  }

  populate() {
    let listModelSchedulingDate = (this.model.controls['listSchedulingDate'] as FormArray);
    if(this.sadtGroupStruct.datetimeCheckEstimative) {
      this.sadtGroupStruct.datetimeCheckEstimative = new Date(this.sadtGroupStruct.datetimeCheckEstimative);
      listModelSchedulingDate.push(
        this.createInputDate(this.sadtGroupStruct.datetimeCheckEstimative)
      );
      if(this.sadtGroupStruct.idUserScheduling != null)
        this.buttonToSaveDisabled = true;
      this.model.get('listSchedulingDate').updateValueAndValidity();
      this.model.get('observation').setValue(this.sadtGroupStruct.schedulingObservation);
    } else {
      this.addNextInputDate();
    }
  }

  close(){
    this.matDialogRef.close(); 
  }

  createInputDate(datetimeCheckEstimative?: Date) {
    if (datetimeCheckEstimative != null) {
      return this.formBuilder.group({
        date: [`${(datetimeCheckEstimative.getDate()).toString().padStart(2, '0')}${(datetimeCheckEstimative.getMonth()+1).toString().padStart(2, '0')}${datetimeCheckEstimative.getFullYear()}`, Validators.required],
        hour: [`${(datetimeCheckEstimative.getHours()).toString().padStart(2, '0')}${(datetimeCheckEstimative.getMinutes()).toString().padStart(2, '0')}`, Validators.required],
      });
    }
    else {
      return this.formBuilder.group({
        date: ['', Validators.required],
        hour: ['', Validators.required],
      });
    }
  }
  
  addNextInputDate() {
    (this.model.controls['listSchedulingDate'] as FormArray).push(this.createInputDate());
  }

  removeSchedulingDate(index: number) {
    (this.model.controls['listSchedulingDate'] as FormArray).removeAt(index);
    if (this.model.get('listSchedulingDate')['controls'].length == 0 && index != 0)
      this.addNextInputDate();

  }

}